import { useRef } from 'react'
import { Box, Flex, Card } from '../primitives'
import GlobalSearch from './GlobalSearch'
import { useRouter } from 'next/router'
import { useHotkeys } from 'react-hotkeys-hook'
import Link from 'next/link'
import * as HoverCard from '@radix-ui/react-hover-card'
import Image from 'next/image'
import { ConnectWalletButton } from '@/components/ConnectWalletButton'
import NavItem from './NavItem'
import HamburgerMenu from './HamburgerMenu'
import MobileSearch from './MobileSearch'
import { useTheme } from 'next-themes'
import { useMediaQuery } from 'react-responsive'
import { useMarketplaceChain, useMounted } from '@/hooks'
import { useAccount } from 'wagmi'
import CartButton from './CartButton'
import { AccountSidebar } from '@/components/navbar/AccountSidebar'
import { MobileConnectWallet } from '../MobileConnectWallet'
export const NAVBAR_HEIGHT = 81
export const NAVBAR_HEIGHT_MOBILE = 77
import { BrowserView, MobileView, isBrowser, isMobile as Mobile } from 'react-device-detect';
const Navbar = () => {
  const { theme } = useTheme()
  const { isConnected } = useAccount()
  const isMounted = useMounted()
  const { routePrefix } = useMarketplaceChain()
  const isMobile = useMediaQuery({ query: '(max-width: 960px' }) || Mobile
  let searchRef = useRef<HTMLInputElement>(null)

  const router = useRouter()
  useHotkeys('meta+k', (e) => {
    e.preventDefault()
    if (searchRef?.current) {
      searchRef?.current?.focus()
    }
  })

  if (!isMounted) {
    return null
  }



  return isMobile ? (
    <Flex
      css={{
        height: NAVBAR_HEIGHT_MOBILE,
        px: '$4',
        width: '100%',
        borderBottom: '1px solid $gray4',
        zIndex: 999,
        background: '$slate1',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
      }}
      align="center"
      justify="between"
    >
      <Box css={{ flex: 1 }}>
        <Flex align="center">
          <Link href={`/${routePrefix}`}>
            <Box css={{ width: 40, cursor: 'pointer' }}>
              <Image
                src="/coinpulseNftLogo.png"
                width={40}
                height={40}
                alt="SemrushNft"
              />
            </Box>
          </Link>
        </Flex>
      </Box>
      <Flex align="center" css={{ gap: '$3' }}>
        <MobileSearch key={`${router.asPath}-search`} />
        <CartButton />
        {!isConnected && (
          <MobileConnectWallet />
        )}
        <HamburgerMenu key={`${router.asPath}-hamburger`} />
      </Flex>
    </Flex>
  ) : (
    <BrowserView>
      <Flex
        css={{
          height: NAVBAR_HEIGHT,
          px: '$5',
          width: '100%',
          maxWidth: 1920,
          mx: 'auto',
          borderBottom: '1px solid $gray4',
          zIndex: 999,
          background: '$neutralBg',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
        }}
        align="center"
        justify="between"
      >
        <Box css={{ flex: 1 }}>
          <Flex align="center">
            <Link href={`/${routePrefix}`}>
              <Box css={{ width: 112, cursor: 'pointer' }}>
                {theme == 'dark' ? (
                  <Image
                    src="/coinpulseNftMarketLogo.png"
                    width={112}
                    height={36}
                    alt="Coinpulse Online Financial Service logo"
                  />
                ) : (
                  <Image
                    src="/coinpulseNftMarketLogoLight.png"
                    width={112}
                    height={36}
                    alt="Coinpulse Online Financial Service logo"
                  />
                )}
              </Box>
            </Link>
            <Box css={{ flex: 1, px: '$5', maxWidth: 600 }}>
              <GlobalSearch
                ref={searchRef}
                placeholder="Search collections and addresses"
                containerCss={{ width: '100%' }}
                key={router.asPath}
              />
            </Box>
            <Flex align="center" css={{ gap: '$5', mr: '$5' }}>

            <Link href='/bounty'>
                <NavItem active={router.pathname == '/bounty'}>100K Bounty</NavItem>
              </Link>
              <Link href='/cpoToken'>
                <NavItem active={router.pathname == '/cpoToken'}>CPO Token</NavItem>
              </Link>

              <Link href={`/${routePrefix}/explore`}>
                <NavItem active={router.pathname == '/explore'}>NFT Explore</NavItem>
              </Link>
              <HoverCard.Root openDelay={200}>
                <HoverCard.Trigger>
                  <NavItem>Trending</NavItem>
                </HoverCard.Trigger>
                <HoverCard.Content sideOffset={24} align="start">
                  <Card css={{ p: 24, width: 240, border: '1px solid $gray4' }}>
                    <Flex css={{ gap: '$4' }} direction="column">
                     
                      <Link href={`/${routePrefix}/collections/trending`}>
                        <NavItem>Collections</NavItem>
                      </Link>
                      <Link href={`/${routePrefix}/mints/trending`}>
                        <NavItem>Mints</NavItem>
                      </Link>
                      <Link href="/portfolio">
                        <NavItem >Sell</NavItem>
                      </Link>

                    </Flex>
                  </Card>
                </HoverCard.Content>
              </HoverCard.Root>


              {isConnected ? (
                <Link href="/portal/Dashboard">
                  <NavItem active={router.pathname == '/portal'}>Dashboard</NavItem>
                </Link>
              ) : (
                <Link href="/portal/InvestmentPlan">
                  <NavItem active={router.pathname == '/portal'}>Investment Plan</NavItem>
                </Link>
              )}
            </Flex>
          </Flex>
        </Box>

        <Flex css={{ gap: '$3' }} justify="end" align="center">
          {/* <ThemeSwitcher /> */}
          <CartButton />
          {isConnected ? (
            <AccountSidebar />
          ) : (
            <Box css={{ maxWidth: '185px' }}>
              <ConnectWalletButton />
            </Box>
          )}
        </Flex>
      </Flex>
    </BrowserView>
  )
}

export default Navbar
