import { Anchor, Box, Button, Flex, Text, Grid } from '@/components/primitives'
import { Avatar } from '@/components/primitives/Avatar'
import * as RadixDialog from '@radix-ui/react-dialog'
import { useContext } from 'react'
import {
  faBars,
  faXmark,
  faRightFromBracket,
  faUser,
  faCog,
  faList,
  faHand,
  faDashboard,
  faSignal,
  faThumbsUp,
  faHandHoldingDollar,
  faCoins,
  faDollar
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArtstation, faTwitter } from '@fortawesome/free-brands-svg-icons'
import Link from 'next/link'
import Image from 'next/image'
import { useAccount, useDisconnect } from 'wagmi'
import { ConnectWalletButton } from '@/components/ConnectWalletButton'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'
import { FullscreenModal } from '@/components/common/FullscreenModal'
import { useENSResolver, useMarketplaceChain } from '@/hooks'
import ThemeSwitcher from '@/components/navbar/ThemeSwitcher'
import Wallet from '@/components/navbar/Wallet'
import { ChainContext } from '@/context/ChainContextProvider'

const HamburgerMenu = () => {
  const { chain } = useContext(ChainContext)
  const { address, isConnected } = useAccount()
  const {
    avatar: ensAvatar,
    shortAddress,
    shortName: shortEnsName,
    profilePic,
    userName
  } = useENSResolver(address)
  const { disconnect } = useDisconnect()
  const { routePrefix } = useMarketplaceChain()

  const trigger = (
    <Button
      css={{ justifyContent: 'center', width: '44px', height: '44px' }}
      type="button"
      size="small"
      color="gray3"
    >
      <FontAwesomeIcon icon={faBars} width={16} height={16} />
    </Button>
  )

  function deleteAllCookies() {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;' +
        'expires=Thu, 01-Jan-1970 00:00:01 GMT;' +
        'path=' + '/;' +
        'domain=' + window.location.host + ';' +
        'secure=;';
    }
  }
  const logOut = () => {

    disconnect()
    deleteAllCookies()
    localStorage.clear()
    sessionStorage.clear()
  }

  return (
    <FullscreenModal trigger={trigger}>
      {' '}
      <Flex
        css={{
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Flex
          css={{
            py: '$4',
            px: '$4',
            width: '100%',
            borderBottom: '1px solid $gray4',
          }}
          align="center"
          justify="between"
        >
          <Link href="/">
            <Box css={{ width: 40, cursor: 'pointer' }}>
              <Image
                src="/coinpulseNftLogo.png"
                width={40}
                height={40}
                alt="CoinPulse Logo"
              />
            </Box>
          </Link>
          <RadixDialog.Close>
            <Flex
              css={{
                justifyContent: 'center',
                width: '44px',
                height: '44px',
                alignItems: 'center',
                borderRadius: 8,
                backgroundColor: '$gray3',
                color: '$gray12',
                '&:hover': {
                  backgroundColor: '$gray4',
                },
              }}
            >
              <FontAwesomeIcon icon={faXmark} width={16} height={16} />
            </Flex>
          </RadixDialog.Close>
        </Flex>
        {isConnected ? (
          <Flex
            css={{
              flexDirection: 'column',
              justifyContent: 'flex-start',
              height: '100%',
              py: '$5',
              px: '$4',
            }}
          >
            <Link href={`/portfolio/${address}`} legacyBehavior>
              <Flex
                css={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  cursor: 'pointer',
                  pb: '$4',
                }}
              >
                <Flex css={{ alignItems: 'center' }}>
                  {ensAvatar || profilePic != null ? (
                    <Avatar size="medium" src={profilePic != null ? profilePic : ensAvatar} />
                  ) : (
                    <Jazzicon
                      diameter={36}
                      seed={jsNumberForAddress(address as string)}
                    />
                  )}
                  <Text style="subtitle1" css={{ ml: '$2' }}>
                    {shortEnsName ? shortEnsName : shortAddress}
                  </Text>
                </Flex>
              </Flex>
            </Link>
            <Link href="/portal/Dashboard" legacyBehavior>
              <Flex
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                  px: "$2",
                  gap: 6,
                  color: '$gray10'
                }}
              >
                <FontAwesomeIcon icon={faDashboard} />
                <Text style="body1">Dashboard</Text>
              </Flex>
            </Link>
            <Link href="/portal/MakeDeposit" legacyBehavior>
              <Flex
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                  px: "$2",
                  gap: 6,
                  color: '$gray10'
                }}
              >
                <FontAwesomeIcon icon={faDollar} />
                <Text style="body1">Investment</Text>
              </Flex>
            </Link>
            <Link href='/cpoToken' legacyBehavior>
              <Flex
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                  px: "$2",
                  gap: 6,
                  color: '$gray10'
                }}
              >
                <FontAwesomeIcon icon={faCoins} />
                <Text style="body1">CPO Token</Text>
              </Flex>
            </Link>
            <Link href={`/${routePrefix}/explore`} legacyBehavior>
              <Flex
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                  px: "$2",
                  gap: 6,
                  color: '$gray10'
                }}
              >
                <FontAwesomeIcon icon={faArtstation} />
                <Text style="body1">NFT Marketplace</Text>
              </Flex>
            </Link>
            <Link href="/claim" legacyBehavior>
              <Flex
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                  px: "$2",
                  gap: 6,
                  color: '$gray10'
                }}
              >
                <FontAwesomeIcon icon={faThumbsUp} />
                <Text style="body1">Claim Bonus</Text>
              </Flex>
            </Link>

            <Grid css={{ gridTemplateColumns: '1fr 1fr', pb: '$4', pt: '24px', }}>
              <Link href="/portal/Profile" replace={true}>
                <Flex
                  align="center"
                  css={{
                    gap: 6,
                    p: '$3',
                    color: '$gray10',
                    cursor: 'pointer',
                  }}
                >
                  <FontAwesomeIcon icon={faUser} />
                  <Text style="body1">Profile</Text>
                </Flex>
              </Link>
              <Link href="/portal/ProfileSetting" replace={true}>
                <Flex
                  align="center"
                  css={{
                    gap: 6,
                    p: '$3',
                    color: '$gray10',
                    cursor: 'pointer',
                  }}
                >
                  <FontAwesomeIcon icon={faCog} />
                  <Text style="body1">Setting</Text>
                </Flex>
              </Link>
            </Grid>
            <Wallet />
            <Flex
              css={{
                justifyContent: 'space-between',
                cursor: 'pointer',
                alignItems: 'center',
                borderBottom: '1px solid $gray4',
              }}
              onClick={() => logOut()}
            >
              <Text
                style="subtitle1"
                css={{
                  pb: '$4',
                  pt: '24px',
                }}
              >
                Logout
              </Text>
              <Box css={{ color: '$gray10' }} >
                <FontAwesomeIcon
                  icon={faRightFromBracket}
                  width={16}
                  height={16}
                />
              </Box>
            </Flex>
          </Flex>
        ) : (
          <Flex
            direction="column"
            justify="between"
            css={{
              height: '100%',
              pb: '$5',
              px: '$4',
            }}
          >
            <Flex direction="column">
              <Link href='/cpoToken' legacyBehavior>
                <Flex
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '24px',
                    width: '100%',
                  }}
                >
                  <Text style="subtitle1">CPO Token</Text>
                </Flex>
              </Link>
              <Link href={`/${chain?.routePrefix}/explore`} legacyBehavior>
                <Text
                  style="subtitle1"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '24px',
                    width: '100%',
                  }}
                >
                  Explore
                </Text>
              </Link>
              <Link href={`/${chain?.routePrefix}/collections/trending`} legacyBehavior>
                <Text
                  style="subtitle1"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '24px',
                    width: '100%',
                  }}
                >
                  Collections
                </Text>
              </Link>
              <Link href={`/${chain?.routePrefix}/mints/trending`} legacyBehavior>
                <Text
                  style="subtitle1"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '24px',
                    width: '100%',
                  }}
                >
                  Mints
                </Text>
              </Link>

              <Link href="/portfolio" legacyBehavior>
                <Text
                  style="subtitle1"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '24px',
                    width: '100%',
                  }}
                >
                  Portfolio
                </Text>
              </Link>
              <Anchor
                href="/portal/InvestmentPlan"
                target="_blank"
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                  width: '100%',
                }}
              >
                <Text style="subtitle1">Investment Plan</Text>
              </Anchor>

              <Anchor
                href="/claim"
                target="_blank"
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                  width: '100%',
                }}
              >
                <Text style="subtitle1">Claim Bonus</Text>
              </Anchor>

            </Flex>
            <Box className='w-full'>
              <Button
                size="large"
                color="gray"
                css={{ 
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                  width: '100%',
                }}
              >
                <ConnectWalletButton />
              </Button>

            </Box>
          </Flex>
        )}
        <Flex
          css={{
            pt: '24px',
            pb: '$5',
            px: '$4',
            gap: '$4',
            width: '100%',
            borderTop: '1px solid $gray4',
          }}
        >
          <a href="https://twitter.com/coinpulsepro" target="_blank">
            <Button
              css={{ justifyContent: 'center', width: '44px', height: '44px' }}
              type="button"
              size="small"
              color="gray3"
            >
              <FontAwesomeIcon icon={faTwitter} width={20} height={20} />
            </Button>
          </a>
          <ThemeSwitcher />
        </Flex>
      </Flex>
    </FullscreenModal>
  )
}
export default HamburgerMenu
