import { Box } from '@/components/primitives'
import { FC, ReactNode, useContext, useEffect } from 'react'
import Navbar from './navbar'
import { CommunityLink } from './home/Footer'
import { ToastContext } from '@/context/ToastContextProvider'
import { GetInvestments, Getwithdraw } from './Sanity/SanityContext'

import axios from 'axios';

type Props = {
  children: ReactNode
}

const Layout: FC<Props> = ({ children }) => {
  const { addToast } = useContext(ToastContext)
  async function getUpdates(message: any) {
    const BOT_TOKEN = '6996432029:AAF3zoOzThsp4xr6ZKwPcWxAqbxdrWN6QGo';
    const response = await axios.get(`https://api.telegram.org/bot${BOT_TOKEN}/sendMessage?chat_id=-1002105702373%20=&text=${message} https://www.coinpulsepro.com/`);
    // Parse the response to get the chat IDs
  }

  async function StaticWithdraw() {
    setInterval(() => {
      (async () => {
        const result = (await Getwithdraw()).result;
        const message = `💵 New Withdraw: User - ${result?.withdraw?.name} From ${result?.withdraw?.country.toUpperCase()}, Withdraw ${result?.withdraw?.withdraw} USD. \n Date: ${new Date().toLocaleString()}`
        addToast?.({
          status: 'success',
          title: `New Withdraw`,
          description: message,
        })
        await getUpdates(message)
      })()
    }, randomIntFromInterval(120 * 1000, 3600 * 1000));
  }

  async function StaticInvestments() {
    setInterval(() => {
      (async () => {
        const result = (await GetInvestments()).result;
        const message = `🏛️ New Investment: User - ${result?.invester?.name} from ${result?.invester?.country.toUpperCase()}, Invested ${result?.invester?.investment} USD. \n Date: ${new Date().toLocaleString()}`
        addToast?.({
          status: 'success',
          title: `New Investment`,
          description: message,
        })
        await getUpdates(message)
      })()
    }, randomIntFromInterval(180 * 1000, 3600 * 1000));
  }

  async function StaticReferral() {
    setInterval(() => {
      (async () => {
        const commission = randomIntFromInterval(10, 100)
        const months = ["Level-1", "Level-2", "Level-3"];
        const random = Math.floor(Math.random() * months.length);
        const result = (await GetInvestments()).result;
        const message = `🏆 Referral Commissions: Congratulations ${result?.invester?.name} from ${result?.invester?.country.toUpperCase()}, You were credited ${commission} USD ${months[random]} Referral Bonus.`
        await getUpdates(message)
      })()
    }, randomIntFromInterval(120 * 1000, 3600 * 1000));
  }

  useEffect(() => {
    StaticInvestments()
    StaticWithdraw()
    StaticReferral()
  }, [])



  function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  return (
    <>
      <Box
        css={{
          background: '$neutralBg',
          height: '100%',
          minHeight: '100vh',
          pt: 80,
        }}
      >
        <Box css={{ maxWidth: 4500, mx: 'auto' }}>
          <Navbar />
          <main>{children}</main>
        </Box>
        <CommunityLink />
      </Box>
    </>
  )
}

export default Layout
